// Footer.js
import React from 'react';
// assets & variables
// import LogoImg from '../assets/logo-test.svg';
import LogoImg from '../assets/repark-logo.png';
import { FOOTER_INFO } from '../variables/footerOption';

const Footer = () => {
  return (
    <footer className="bg-[#333333] text-[#FFFFFF] md:h-[175px] w-full flex items-center">
      <div className="container mx-auto flex flex-wrap px-8 md:w-[62%] md:px-0 my-16 md:my-0 ">
        <div className="w-full md:w-1/4 mb-14 md:my-0 flex items-center">
          <img className='img-comp h-[65px]' src= {LogoImg} alt='logo'/>
        </div>
        <div className="w-full md:w-1/2 mb-12 md:mb-0">
          <p className="text-sm">{FOOTER_INFO.address}</p>
          <p className="text-sm">{FOOTER_INFO.companyInfo}</p>
          <p className="mt-2 text-sm">NINEWATT All rights reserved.</p>
        </div>
        <div className="w-full md:w-1/4 mb-6 md:mb-0 text-right">
          <p className="mb-2">도입 및 이용문의</p>
          <p>Tel : {FOOTER_INFO.tel}</p>
          <p>e-mail : {FOOTER_INFO.email}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;