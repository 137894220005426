// Main.js
import React from 'react';
// assets
import LogoImg from '../assets/main-img.png'; 


const Main = () => {

  const handleOpenNewTab = () => {
    const LOGIN_URL = process.env.REACT_APP_URL_ADMIN;  
    window.open(LOGIN_URL, "_blank", "noopener, noreferrer");
  };


  return (
    <main className="container mx-auto grow flex items-center justify-center relative md:w-[62%]">
      {/* Your main content */}
      <div className="px-8 py-10 md:py-0 md:absolute md:inset-o md:h-[70%]">
      <div className="flex flex-wrap h-full items-center justify-center">
        <div className="w-full md:w-1/2 mb-6 md:mb-0">
          <p className="text-lg md:text-xl text-[#555555]">시민-관리자 보수업체를 위한</p>
          <p className="text-3xl md:text-6xl font-bold text-[#3C4132] my-3">공원시설물 관리시스템</p>
          <ul className="text-[#828587] leading-loose">
            <li>- 위치정보와 시설물정보가 반영된 QR TAG 스마트 고장신고 시스템</li>
            <li>- 데이터 구축·전용 표찰 제작부터 관리시스템까지 제공</li>
            <li>- 별도 설치 필요없는 SaaS형 클라우드 서비스</li>
            <li>- 관리자의 업무 방식 디지털 전환</li>
          </ul>
          <button className="w-32 h-12 my-4 border-solid border-[#FFC815] border-2 rounded-full" 
          type='button' onClick={() => handleOpenNewTab()}> 
            <span className='text-lg font-normal'>로그인</span> 
          </button> 
        </div>
        <div className="w-full md:w-1/2 px-4 mb-6 md:mb-0">
        <img className='img-comp w-[95%]' src= {LogoImg} alt='logo'/>
        </div>
      </div>
      </div>
    </main>
  );
};

export default Main;
