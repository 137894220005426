import React from 'react';
import Header from '../components/Header';
import Main from '../components/Main';
import Footer from '../components/Footer';

function MainHome() {
  return (
    <div className="flex flex-col min-h-screen bg-[#EFEFEF] overflow-auto w-full">
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default MainHome;