import React from 'react';

function ErrorPage404() {

    return (
        <div className="px-10 py-10 w-full h-full flex justify-center items-center">
            <div>
            <h3 className='font-bold text-3xl mb-3'>404 Page Not Found </h3>
            <h3> 페이지를 찾을 수 없습니다.</h3>
            </div>
        </div>
    );
  }



export default ErrorPage404;
