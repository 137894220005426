//React
// import { useNavigate, Navigate } from 'react-router-dom';
// import React, { useEffect } from 'react';
import { Route, Routes} from 'react-router-dom'; 
import './App.css';

// pages & Components
import ErrorPage404 from './pages/ErrorPage404.js';
import MainHome from './pages/MainHome'

function App() {


    return (
                <div className="">
              <Routes>
              <Route path="/" element={<MainHome/>} />
              <Route path="*" element={<ErrorPage404 />} />
              </Routes>
          </div>
    )
};

export default App;
