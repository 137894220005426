// Header.js
import React from 'react';
// assets
import LogoImg from '../assets/repark-logo.png';
// import LogoImg from '../assets/logo-test.svg';
 
const Header = () => {
  return (
    <header>
      <nav className="container mx-auto pt-10">
        <ul className="flex items-center justify-center">
          <img className='img-comp h-[70px]' src= {LogoImg} alt='logo'/>
        </ul>
      </nav>
    </header>
  );
};

export default Header;